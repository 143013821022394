import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotices: builder.query({ query: () => `notice`, keepUnusedDataFor: 0 }),
        getNoticeById: builder.query({ query: (id) => `notice?id=${id}`, keepUnusedDataFor: 0 }),
        createNotice: builder.mutation({ query: (body) => ({ url: `notice`, method: 'POST', body }) }),
        updateNotice: builder.mutation({ query: ({ id, body }) => ({ url: `notice?id=${id}`, method: 'PATCH', body }) }),
        deleteNotice: builder.mutation({ query: (id) => ({ url: `notice?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetNoticesQuery,
    useGetNoticeByIdQuery,
    useCreateNoticeMutation,
    useUpdateNoticeMutation,
    useDeleteNoticeMutation,
} = extendedApiSlice
