import React, {  useState } from 'react'
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import Loading from '../ui/Loading';
import ApiErrorModal from './ApiErrorModal';
import Check from '../form/Check';
import { useCreatePolicyMutation,  useDeleteTermsMutation, useGetPolicyQuery,  useUpdatePolicyMutation,  } from '../../services/adminSlice';

import FormattedInput from '../form/FormattedInput';

const ViewEditPolicySettingModal = ({ refetch, id, isCreate }) => {

    const modalId = "ViewEditPolicySettingModal" + id

    const [createTerm, createTermRes] = useCreatePolicyMutation();
    const [updateTerm, updateTermRes] = useUpdatePolicyMutation();
    const getTerms = useGetPolicyQuery()

    const [formEnabled, setFormEnabled] = useState(false)

    const [deleteDownloadable, deleteDownloadableRes] = useDeleteTermsMutation()

    const handleDelete = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (isConfirmed) {
            deleteDownloadable(id).then((res) => {
                if (!res.error) {
                    document.getElementById(modalId + 'CloseBtn').click();
                    refetch();
                }
            });
        } else {
            console.log("Deletion canceled by user");
        }
    };


    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                content: form['formattedInput'].value,
            }

            if (isCreate) {
                createTerm(body).then((res) => {
                    if (!res.error) {
                        // document.getElementById(modalId + 'CloseBtn').click();
                        refetch();
                        form.classList.remove('was-validated')
                    }
                })
            }
            else {
                updateTerm({ id, body }).then((res) => {
                    if (!res.error) {
                        document.getElementById(modalId + 'CloseBtn').click();
                        refetch();
                        form.classList.remove('was-validated')
                    }
                })
            }

        }
        else form.classList.add('was-validated')

    }

    if (getTerms.isLoading) return <Loading />
    if (getTerms.isError) return <ApiErrorModal response={getTerms} />



    return (
        <>



            {
                isCreate ?
                    <div className="btn btn-primary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Create</div>
                    :
                    <div className="btn btn-secondary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Edit</div>
            }

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">

                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>

                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Terms and condiations</div>
                                <button id={modalId + "CloseBtn"} type="button" className="btn-close me-2 mt-2" data-bs-dismiss="modal" aria-label="Close"></button>

                            </div>
                            <div className="modal-body text-dark p-5">

                                <h5 className="fw-bold mb-3">Primary Details</h5>

                                <FormattedInput defaultValue={getTerms?.data[0]?.content} disabled={!formEnabled} />



                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {updateTerm.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(updateTerm).label}: {getApiErrorMessage(updateTerm).message}
                                    </div>
                                }

                                <Check name={"Check to edit"} setChecked={setFormEnabled} containerClass={"me-3"} required />


                                <Button className='btn btn-warning rounded-pill' type="button" disabled={!formEnabled} onClick={handleDelete}>Delete</Button>
                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Updating"
                                    res={updateTerm}
                                    disabled={!formEnabled}
                                    type='submit'
                                >
                                    Update
                                </Button>

                            </div>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}

export default ViewEditPolicySettingModal