import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import { store } from './redux/store'
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";


const root = ReactDOM.createRoot(document.getElementById('root'));

const persistor = persistStore(store)

root.render(<Provider store={store}>


  <BrowserRouter>
    <PersistGate persistor={persistor}>
      <App></App>

    </PersistGate>
  </BrowserRouter>
</Provider>);