import React from 'react'
import Input from '../../components/form/Input'
import Select from '../../components/form/Select'
import { useCreateDownloadableMutation, useGetDownloadableByIdQuery, useUpdateDownloadableMutation } from '../../services/downloadablesSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Button from '../../components/form/Button'
import { useParams } from 'react-router-dom'

const EditDownloadables = () => {

    const { id } = useParams()

    const type = ["document", "video", "audio", "picture"]
    const containerClass = "mb-3"

    const [updateDownloadable, updateDownloadableRes] = useUpdateDownloadableMutation();
    const downloadableRes = useGetDownloadableByIdQuery(id)

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                name: form['File Name'].value,
                type: form['File Type'].value,
                link: form['File Link'].value
            }

            updateDownloadable({id, body})
                // .then((res) => {
                //     if (!res.error) {
                //         form.reset()
                //         form.classList.remove('was-validated')
                //     }
                // })

        }
        else form.classList.add('was-validated')
    }

    return (
        <div className='p-5 text-center'>

            {updateDownloadableRes.isSuccess && <SuccessModal label={"Updated!"} message={"Downloadable has been updated."} to={{ link: "/downloadables", text: "Go to Downloadables" }} closeTo={"/downloadables"} />}
            {updateDownloadableRes.isError && <ApiErrorModal response={updateDownloadableRes} />}

            <form className="" onSubmit={onSubmit} noValidate>
                <Input name={"File Name"} inputClass={'bg-white'} containerClass={containerClass} defaultValue={downloadableRes.data?.name} required />
                <Select name={"File Type"} options={type} inputClass={'bg-white'} containerClass={containerClass} selectedValue={downloadableRes.data?.type} />
                <Input name={"File Link"} inputClass={'bg-white'} containerClass={containerClass} defaultValue={downloadableRes.data?.link} required />
                {/* <div className="card p-5 rounded-5 text-center">
                    <label htmlFor="file" className='p-5 rounded-3 text-primary' style={{ borderStyle: 'dashed', borderColor: '#D0DBF8' }}>Browse file to upload</label>
                    <input type="file" className='d-none' id='file' name='file' />
                </div> */}
                <Button
                    className='btn btn-primary text-white rounded-pill text-nowrap px-5 my-3'
                    res={updateDownloadableRes}
                    loadingLabel={"Updating..."}
                >
                    Update
                </Button>
            </form>
        </div >
    )
}

export default EditDownloadables