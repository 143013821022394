import React from 'react'

const Loading = () => {
    return (
        <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
            <div class="spinner-border" style={{ width: 50, height: 50 }} role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loading