import Cropper from 'cropperjs';
import React, { useEffect, useRef, useState } from 'react'

const ImageInputCropper = ({ aspectRatio, file, setFile, labelName, labelClass, imgClass, labelStyle, containerClass, ...attributes }) => {

    const name = "ImageInputCropperInput"
    const [selectedImage, setSelectedImage] = useState(null)
    const fileInputRef = useRef(null);

    const containerClassName = "d-flex flex-column align-items- fs-13 " + (containerClass ? containerClass : "")
    const labelClassName = '' + (labelClass ? labelClass : "")
    let cropper


    const initiateCropper = () => {
        const img = document.getElementById("imgInput")
        cropper = new Cropper(img, { aspectRatio: aspectRatio ? aspectRatio : 1, viewMode: 1 })
    }


    const onCrop = () => {
        cropper
            .getCroppedCanvas()
            .toBlob((blob) => {
                const ext = '.' + blob.type.substring(blob.type.indexOf('/') + 1)
                const file = new File([blob], 'file' + ext, { type: blob.type });
                setFile(file)
                setSelectedImage(null)
            })
        cropper.destroy()
    }

    const onChange = (e) => {
        if (e.target.files.length > 0) setSelectedImage(e.target.files[0])
        else setFile()
    }

    useEffect(() => {
        const inputElement = fileInputRef.current;
        const form = inputElement?.form;

        if (form) {
            const handleReset = () => setFile(null);
            form.addEventListener('reset', handleReset);
            return () => { form.removeEventListener('reset', handleReset); }
        }
    }, []);

    useEffect(() => { if (selectedImage) initiateCropper() }, [selectedImage])

    if (selectedImage) {
        return (
            <div className='text-end'>
                <div className="d-flex align-items-center justify-content-center w-100 p-0" style={{ maxHeight: 400 }}>
                    <img src={URL.createObjectURL(selectedImage)} className='' id='imgInput' alt='crop' />
                </div>

                <button type='button' className='btn btn-primary ms-auto mt-3' onClick={onCrop} >Crop</button>
            </div>
        )
    }

    return (
        <div className={containerClassName}>
            {
                file ?
                    <label htmlFor={name}>
                        <img
                            src={URL.createObjectURL(file)} className={imgClass ? imgClass : 'w-100'} alt="" />
                    </label>
                    :
                    <label htmlFor={name} className={labelClassName} style={labelStyle ? labelStyle : {}}>{labelName ? labelName : "Select Image"}</label>
            }

            <input
                type={"file"}
                className={"d-none"}
                id={name}
                name={name}
                ref={fileInputRef}
                onChange={onChange}
                {...attributes}
            />

        </div>
    )
}

export default ImageInputCropper
