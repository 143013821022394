import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setAuthToken } from '../../redux/authSlice'

const SignInByToken = () => {
    const { token } = useParams()
    const navigate = useNavigate()

    const dispatch = useDispatch()
    useEffect(() => {
        if (token) {
            dispatch(setAuthToken(token))
            navigate("/")

        }
    }, [])
    return (<h1>Hellow</h1>)
}

export default SignInByToken
